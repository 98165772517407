<template>
  <div v-loading="loading">
    <el-card>
      <div class="flex flex-align-center flex-justify-between" style="gap:100px">
        <div class="flex flex-align-center font-14">
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/sellcustomerManagePageCompanyImg.png" style="width:50px;height:50px" alt="">
          <div class="color-999999 margin-left-10 item-label" style="width:fit-content">公司名称</div>
          <div class="color-606266 margin-left-30">{{form.CompanyName}}</div>
        </div>
        <div class="flex flex-align-center gap-10">
          <buttonPermissions datas="sellManageEdit" v-if="!form.IsCancel">
            <el-button @click="toEdit">编辑</el-button>
          </buttonPermissions>
		  <buttonPermissions datas="sellManageSetLabel">
		    <el-button @click="handleShowTagPop">设置标签</el-button>
		  </buttonPermissions>
          <buttonPermissions :datas="'sellManageEdit'" v-if="![4].includes(form.CooperateState)&&!form.IsCancel">
            <el-button @click="toChangeCharge">更换负责人</el-button>
          </buttonPermissions>
          <buttonPermissions :datas="'sellManageEdit'" v-if="![3,4].includes(form.CooperateState)&&!form.IsCancel">
            <el-button @click="toVoid">作废</el-button>
          </buttonPermissions>
          <buttonPermissions :datas="'sellManageEdit'" v-if="[3].includes(form.CooperateState)&&!form.IsCancel">
            <el-button @click="toStop">终止合作</el-button>
          </buttonPermissions>
        </div>
      </div>
      <div style="margin-top:15px" class="form font-14">
        <div class="flex flex-align-center flex-wrap gap-10">
          <div class="flex flex-align-center width-30">
            <div class="color-999999 item-label" style="">客户编码</div>
            <div class="color-606266">
              {{form.CRMCustomerNo}}
            </div>
          </div>
          <div class="flex flex-align-center width-30">
            <div class="color-999999 item-label">客户级别</div>
            <div class="color-606266">
              {{form.CRMCustomerLevelValue}}
            </div>
          </div>
          <div class="flex flex-align-center width-30">
            <div class="color-999999 item-label">客户类型</div>
            <div class="color-606266">
              {{form.TypeName}}
            </div>
          </div>
          <div class="flex flex-align-center width-30">
            <div class="color-999999 item-label">合作状态</div>
            <div class="color-606266">
              <el-dropdown @command="handleCommand">
                <span style="cursor: pointer;">
                  {{form.CooperateStateValue}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="v" v-for="(v,i) in cooperation" :key="i">{{v.label}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="flex flex-align-center width-30">
            <div class="color-999999 item-label">负责人</div>
            <div class="color-606266">
              {{form.EmployeeName}}({{form.Phone}})
            </div>
          </div>
          <div class="flex flex-align-center width-30">
            <div class="color-999999 item-label">添加时间</div>
            <div class="color-606266">
              {{form.AddTime}}
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="margin-top-10">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="v.label" v-for="(v,i) in tabsList" :key="i" :name="v.value"></el-tab-pane>
      </el-tabs>
      <div v-if="activeName=='0'" style="padding-bottom:40px">
        <div>
          <div class="titleBox">基本信息</div>
          <div class="flex flex-wrap font-14">
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">公司名称</div>
              <div class="color-606266">
                {{form.CompanyName||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">客户编码</div>
              <div class="color-606266">
                {{form.CRMCustomerNo||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">客户来源</div>
              <div class="color-606266">
                {{form.FromName||'--'}} {{form.CRMCustomerFromRemark}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">客户类型</div>
              <div class="color-606266">
                {{form.TypeName||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">客户级别</div>
              <div class="color-606266">
                {{form.CRMCustomerLevelValue||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">合作状态</div>
              <div class="color-606266">
                {{form.CooperateStateValue||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">负责人</div>
              <div class="color-606266">
                <div v-if="form.EmployeeName">
                  {{form.EmployeeName}}({{form.Phone}})
                </div>
                <div v-else>--</div>
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">源线索</div>
              <div class="color-606266">
                <el-button type="text" style="margin:0;padding:0" @click="toclueDataDetails" v-if="form.ClueCompanyName">{{form.ClueCompanyName}}</el-button>
                <div v-else>--</div>
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">销售区域</div>
              <div class="color-606266">
                <div v-if="form.SellCity">
                  {{form.SellProvince}}/{{form.SellCity}}
                </div>
                <div v-else>--</div>
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">联系人姓名</div>
              <div class="color-606266">
                {{form.ContactName||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">联系人手机号</div>
              <div class="color-606266">
                {{form.ContactPhone||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">联系人职务</div>
              <div class="color-606266">
                {{form.ContactPosition||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">固话</div>
              <div class="color-606266">
                <div v-if="form.TelephoneAreaCode||form.Telephone">
                  {{form.TelephoneAreaCode}}-{{form.Telephone}}
                </div>
                <div v-else>--</div>
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">销售备注</div>
              <div class="color-606266">
                {{form.SellRemark||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">所在地区</div>
              <div class="color-606266">
                <div v-if="form.Area">
                  {{form.Province}}/{{form.City}}/{{form.Area}}
                </div>
                <div v-else>--</div>
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">详细地址</div>
              <div class="color-606266">
                {{form.Address||'--'}}
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top:40px">
          <div class="titleBox">工商信息</div>
          <div class="flex flex-wrap font-14">
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">营业执照名称</div>
              <div class="color-606266">
                {{form.BusinessLicenseName||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">工商注册号</div>
              <div class="color-606266">
                {{form.BusinessRegistrationNo||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">统一社会信用代码</div>
              <div class="color-606266">
                {{form.UnifiedSocialCreditIdentifier||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">工商注册日期</div>
              <div class="color-606266">
                <div v-if="form.BusinessRegistrationData">
                  {{form.BusinessRegistrationData.substring(0,10)}}
                </div>
                <div v-else>--</div>
              </div>
            </div>
          </div>
        </div>
		<div style="margin-top:40px" v-if="selected.length">
		  <div class="titleBox">标签信息</div>
		  <div class="flex flex-wrap font-14" style="margin: 20px 10px;">
		    <el-tag type="info" v-for="(item, index) in selected" :key="index" style="margin-right: 10px;margin-bottom: 10px;">{{ item.Name }}</el-tag>
		  </div>
		</div>
        <div style="margin-top:40px" v-if="form.IsCancel">
          <div class="titleBox">其他信息</div>
          <div class="flex flex-wrap font-14">
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">作废原因</div>
              <div class="color-606266">
                {{form.CancelReasonTypeValue||'--'}} {{form.CancelReason}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">作废备注</div>
              <div class="color-606266">
                {{form.CancelRemark||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">作废时间</div>
              <div class="color-606266">
                {{form.CancelTime||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">操作人</div>
              <div class="color-606266">
                <span v-if="form.CancelOperatorEmployeeName">
                  {{form.CancelOperatorEmployeeName}}({{form.CancelOperatorEmployeePhone}})
                </span>
                <span v-else>--</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeName=='1'">
        <div class="flex flex-align-center flex-justify-between">
          <div class="color-606266 font-14">全部商机（{{total}}）</div>
          <buttonPermissions :datas="'sellManageChanceEdit'">
            <el-button @click="toAddChance({Id:0})">新增商机</el-button>
          </buttonPermissions>
        </div>
        <el-table :data="tablelist" v-loading="tableloading" class="margin-top-10" tooltip-effect="dark popper-max500">
          <el-table-column prop="" label="商机名称">
            <template slot-scope="scope">
              <div>
                <el-popover placement="top-start" trigger="hover">
                  <div style="max-width:200px">{{scope.row.BusinessOpportunityName}}</div>
                  <div slot="reference" class="ellipsis">{{scope.row.BusinessOpportunityName}}</div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="商机负责人">
            <template slot-scope="scope">
              <div>
                {{scope.row.MallEmployeeName}}({{scope.row.MallEmployeePhone}})
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="阶段-赢率">
            <template slot-scope="scope">
              <div :style="{color:stageColor[scope.row.Stage]}">
                {{scope.row.StageDisplay}}({{(scope.row.WinRate*10000).toFixed(0)/100}}%)
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="StageChangeTime" label="阶段变更时间"></el-table-column>
          <el-table-column prop="" label="商机备注" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                <div class="ellipsis">{{scope.row.BusinessOpportunityRemark||'--'}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="输单/无效原因" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                <div class="ellipsis">{{scope.row.LoseReasonDisplay||'--'}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="创建人">
            <template slot-scope="scope">
              <div>
                {{scope.row.CreateMallEmployeeName}}({{scope.row.CreateMallEmployeePhone}})
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="创建时间"></el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <div class="flex flex-align-center gap-10">
                <buttonPermissions :datas="'sellManageChanceEdit'">
                  <el-button type="text" @click="toAddChance(scope.row)">编辑</el-button>
                </buttonPermissions>
                <buttonPermissions :datas="'sellManageChanceChangeStage'">
                  <el-button type="text" @click="editStage(scope.row)">变更阶段</el-button>
                </buttonPermissions>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="text-align:right" class="margin-top-10" v-if="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div v-if="activeName=='2'" style="max-height:600px;overflow-y:auto">
        <div class="font-14 color-606266">客户日志</div>
        <el-timeline style="padding:0" class="margin-top-10">
          <el-timeline-item v-for="(v,i) in form.RecordItemList" :timestamp="v.AddTime" placement="top">
            <el-card>
              <div class="color-606266">{{v.RecordTypeValue}}</div>
              <div class="color-999999" style="margin-top:16px">{{v.Remark}}</div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-card>

    
    <el-dialog :visible.sync="chargeShow" title="更换销售负责人" width="500px" custom-class="dialog-body-paddingTop-10">
      <div class="form" style="margin-top:0px">
        <div class="form-label label-star">将负责人更换为：</div>
        <el-select v-model="chareMsg.MallEmployeeId" class="margin-top-10" style="width:100%">
          <el-option v-for="(v,i) in chargeList" :key="i" :value="v.Id" :label="v.EmployeeName+`(${v.Phone})`"></el-option>
        </el-select>
      </div>
      <div class="flex flex-align-center gap-10 margin-top-20">
        <el-button style="margin-left:auto" @click="chargeShow=false">关闭</el-button>
        <el-button type="primary" @click="confirmCharge">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="viodShow" title="作废客户" width="500px" custom-class="dialog-body-paddingTop-10">
      <div class="form" style="margin-top:0px">
        <div class="flex gap-10">
          <div class="form-label label-star">作废原因：</div>
          <div class="flex-1">
            <el-select v-model="viodMsg.CancelReasonType" style="width:100%" placeholder="请选择">
              <el-option v-for="(v,i) in viodList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
            <el-input v-model="viodMsg.CancelReason" v-if="viodMsg.CancelReasonType==30" type="textarea" :rows="2"
             class="margin-top-10" style="width:100%" maxlength="100"></el-input>
          </div>
        </div>
        <div class="flex gap-10 margin-top-10">
          <div class="form-label">备注：</div>
          <el-input v-model="viodMsg.CancelRemark" type="textarea" :rows="4" style="width:100" maxlength="500"></el-input>
        </div>
      </div>
      <div class="flex flex-align-center gap-10 margin-top-20">
        <el-button style="margin-left:auto" @click="viodShow=false">关闭</el-button>
        <el-button type="primary" @click="confirmViod">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="stopShow" title="终止合作" width="500px" custom-class="dialog-body-paddingTop-10">
      <div class="form" style="margin-top:0px">
        <div class="form-label label-star">终止合作备注：</div>
        <el-input v-model="stopMsg.TerminateCooperateRemark" type="textarea" :rows="4" class="margin-top-10" style="width:100%"
          maxlength="500"></el-input>
      </div>
      <div class="flex flex-align-center gap-10 margin-top-20">
        <el-button style="margin-left:auto" @click="stopShow=false">关闭</el-button>
        <el-button type="primary" @click="confirmStop">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="chanceShow" :title="rowmsg.Id?'编辑商机':'新增商机'" width="500px" custom-class="dialog-body-paddingTop-10">
      <div class="form" style="margin-top:0px">
        <div class="flex gap-10">
          <div class="form-label label-star">商机名称：</div>
          <el-input v-model="chanceMsg.BusinessOpportunityName" placeholder="商机名称" style="width:100%" maxlength="100"></el-input>
        </div>
        <div class="flex gap-10 margin-top-10">
          <div class="form-label">备注：</div>
          <el-input v-model="chanceMsg.BusinessOpportunityRemark" type="textarea" :rows="4" style="width:100%" maxlength="500"></el-input>
        </div>
      </div>
      <div class="flex flex-align-center gap-10 margin-top-20">
        <el-button style="margin-left:auto" @click="chanceShow=false">关闭</el-button>
        <el-button type="primary" @click="confirmChance">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="stageShow" title="变更商机阶段" width="500px" custom-class="dialog-body-paddingTop-10">
      <div class="form" style="margin-top:0px">
        <div>
          <div class="form-label label-star">将阶段变更为：</div>
          <el-select v-model="rowmsg.Stage" class="margin-top-10" style="width:100%"
           @change="rowmsg.LoseReason='',rowmsg.BusinessOpportunityRemark=''">
            <el-option v-for="(v,i) in stageList" :key="i" :value="v.value" :label="v.label"></el-option>
          </el-select>
        </div>
        <div class="margin-top-20" v-if="rowmsg.Stage==60">
          <div class="form-label label-star">输单原因：</div>
          <el-select v-model="rowmsg.LoseReason" class="margin-top-10" style="width:100%">
            <el-option v-for="(v,i) in loseList" :key="i" :value="v.value" :label="v.value"></el-option>
          </el-select>
          <el-input v-model="rowmsg.LoseOtherReason" type="textarea" :rows="2" style="width:100%" maxlength="100"
            class="margin-top-10" v-if="rowmsg.LoseReason=='其他'"></el-input>
        </div>
        <div class="margin-top-20" v-if="rowmsg.Stage==70">
          <div class="form-label label-star">无效原因：</div>
          <el-select v-model="rowmsg.LoseReason" class="margin-top-10" style="width:100%">
            <el-option v-for="(v,i) in invalid" :key="i" :value="v.value" :label="v.value"></el-option>
          </el-select>
          <el-input v-model="rowmsg.LoseOtherReason" type="textarea" :rows="2" style="width:100%" maxlength="100"
            class="margin-top-10" v-if="rowmsg.LoseReason=='其他'"></el-input>
        </div>
        <div class="margin-top-20" v-if="rowmsg.Stage==60||rowmsg.Stage==70">
          <div class="form-label" style="text-align:left;margin-left:5px">备注：</div>
          <el-input v-model="rowmsg.LoseRemark" type="textarea" :rows="4" style="width:100%" maxlength="500"></el-input>
        </div>
      </div>
      <div class="flex flex-align-center gap-10 margin-top-20">
        <el-button style="margin-left:auto" @click="stageShow=false">关闭</el-button>
        <el-button type="primary" @click="confirmEditStage">确定</el-button>
      </div>
    </el-dialog>
	
	<tagging :dialogTag="dialogTag" :title="tagTitle" @close="closedialogTag" @confirm="confirm"
		:selected="selected" :showAddBtn="false">
	</tagging>
	
  </div>
</template>

<script>
import {
  crmCustomerlist,
  crmCustomercooperateStateEdit,
  crmCustomercrmCustomerCancel,
  crmCustomerchangeEmployeeId,
  crmCustomerinfo,
  crmCustomerBusinessOpportunitylist,
  crmCustomerBusinessOpportunityadd,
  crmCustomerBusinessOpportunitymodify,
  crmCustomerBusinessOpportunitystageChange,
  cRMCustomerTagRelationModify,
  cRMCustomerTagRelationList
} from "@/api/sv3.0.0"
import {
  employeelistbystate
} from "@/api/TurnTomySelf"
import buttonPermissions from '@/components/buttonPermissions';
import tagging from "./tagging"
export default {
  components: {
    buttonPermissions,
	tagging
  },
  data () {
    return {
      loading:false,
      cooperation:[
        {value:1,label:'未合作'},
        {value:2,label:'有意向'},
        {value:3,label:'合作中'},
      ],
      activeName:'0',
      tabsList:[
        {value:'0',label:'详情'},
        {value:'1',label:'商机'},
        {value:'2',label:'日志'},
      ],
      form:{},
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
      chargeShow:false,
      chareMsg:{},
      chargeList:[],
      viodShow:false,
      viodMsg:{},
      viodList:[
        {value:10,label:'客户暂时无需求'},
        {value:20,label:'联系不上客户'},
        {value:30,label:'其他'},
      ],
      stopShow:false,
      stopMsg:{},
      rowmsg:{},
      chanceShow:false,
      chanceMsg:{},
      stageShow:false,
      stageList:[
        {value:10,label:'初步交流'},
        {value:20,label:'需求沟通'},
        {value:30,label:'方案报价'},
        {value:40,label:'商务谈判'},
        {value:50,label:'赢单'},
        {value:60,label:'输单'},
        {value:70,label:'无效'},
      ],
      stageColor:{
        10:'#409EFFFF',
        20:'#E6A23CFF',
        30:'#E6A23CFF',
        40:'#95D576FF',
        50:'#67C23AFF',
        60:'#999999FF',
        70:'#999999FF',
      },
      loseList:[
        {value:'客户暂时无需求'},
        {value:'商品不符合客户需求'},
        {value:'客户预算不足'},
        {value:'其他'},
      ],
      invalid:[
        {value:'客户取消交易'},
        {value:'商品不符合客户需求'},
        {value:'联系不上客户'},
        {value:'其他'},
      ],
	  
	  dialogTag: false,
	  tagTitle: '设置标签',
	  selected: [],
    }
  },
  async mounted () {
    await this.getInfo();
    if(this.$route.query.tab){
      this.activeName = this.$route.query.tab
      this.gettablelist()
    }
	this.getTags();
  },
  methods:{
	async getTags(){
		try{
			const res = await cRMCustomerTagRelationList({
				CRMCustomerId: this.$route.query.Id
			})
			this.selected = res.Result;
		}catch(e){
			//TODO handle the exception
		}
	},
	/**
	 * 设置标签
	 */
	handleShowTagPop(){
		this.dialogTag = true;
	},
	closedialogTag() {
		this.dialogTag = false;
		this.isClearTag = false;
	},
	async confirm(e) {
		console.log(e);

		if (this.isSubmit) return;
		this.isSubmit = true;
		
		if (this.isClearTag) {
			this.clearTagFn(e);
			return
		}
	
		const loading = this.$loading({
			lock: true,
			text: 'Loading',
			// spinner: 'el-icon-loading',
			background: 'rgba(39, 37, 37, 0.8);'
		});
		try {
			let params = {
				CRMCustomerId: this.$route.query.Id,
				CRMTagDetailIdList: e.map(item=>{
					return item.CRMTagDetailId
				})
			}
			let res = await cRMCustomerTagRelationModify(params);
			this.$message.success('操作成功');
			this.getTags();
		} finally {
			this.dialogTag = false
			loading.close();
			this.getInfo()
			
			setTimeout(()=>{
				this.isSubmit = false;
			}, 2000)
		}
	
	},
	  
    toclueDataDetails(){
      this.$router.push({
        name: 'clueDataDetails',
        query: {
          id:this.form.CRMClueId
        }
      })
    },
    async getInfo(){
      this.loading = true
      try{
        let res = await crmCustomerinfo({
          Id:this.$route.query.Id
        })
        if(res.IsSuccess){
          let form = res.Result
          this.form = form
        }
      }finally{
        this.loading = false
      }
    },
    toAddChance(row){
      this.rowmsg = JSON.parse(JSON.stringify(row))
      this.chanceMsg = {
        BusinessOpportunityName:this.rowmsg.BusinessOpportunityName||'',
        BusinessOpportunityRemark:this.rowmsg.BusinessOpportunityRemark||''
      }
      this.chanceShow = true
    },
    async confirmChance(){
      try{
        let res
        if(this.rowmsg.Id){
          res = await crmCustomerBusinessOpportunitymodify({
            Id:this.rowmsg.Id,
            ...this.chanceMsg,
          })
        }else{
          res = await crmCustomerBusinessOpportunityadd({
            CRMCustomerId:this.form.Id,
            ...this.chanceMsg,
          })
        }
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.chanceShow = false
          this.gettablelist()
        }
      }finally{
        //
      }
    },
    async confirmEditStage(){
      if(!this.rowmsg.Stage){
        this.$message.error('请选择变更阶段')
        return
      }else{
        if([60,70].includes(this.rowmsg.Stage)&&!this.rowmsg.LoseReason){
          this.$message.error(`请选择${this.rowmsg.Stage==60?'输单':'无效'}原因`)
          return
        }
      }
      try{
        let msg = JSON.parse(JSON.stringify(this.rowmsg))
        if(![60,70].includes(msg.Stage)){
          msg.LoseOtherReason = ''
          msg.LoseReason = ''
          msg.LoseRemark = ''
        }
        if(msg.LoseReason!='其他'){
          msg.LoseOtherReason = ''
        }
        let res = await crmCustomerBusinessOpportunitystageChange(msg)
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.stageShow = false
          this.gettablelist()
        }
      }finally{
        //
      }
    },
    editStage(row){
      this.rowmsg = JSON.parse(JSON.stringify({
        Id:row.Id||'',
        Stage:row.Stage||'',
        LoseReason:row.LoseReason||'',
        LoseOtherReason:row.LoseOtherReason||'',
        LoseRemark:row.LoseRemark||''
      }))
      // console.log(this.rowmsg)
      this.stageShow = true
    },
    async confirmStop(){
      if(!this.stopMsg.TerminateCooperateRemark){
        this.$message.error('请输入终止合作原因')
        return
      }
      try{
        let res = await crmCustomercooperateStateEdit({
          Id:this.form.Id,
          CooperateState:4,
          ...this.stopMsg,
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.stopShow = false
          this.getInfo()
        }
      }finally{
        //
      }
    },
    toStop(row){
      this.rowmsg = JSON.parse(JSON.stringify(row))
      this.stopMsg = {
        TerminateCooperateRemark:''
      }
      this.stopShow = true
    },
    async confirmViod(){
      if(!this.viodMsg.CancelReasonType){
        this.$message.error('请选择作废原因')
        return
      }
      try{
        let res = await crmCustomercrmCustomerCancel({
          Id:this.form.Id,
          ...this.viodMsg,
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.viodShow = false
          this.getInfo()
        }
      }finally{
        //
      }
    },
    toVoid(row){
      this.rowmsg = JSON.parse(JSON.stringify(row))
      this.viodMsg = {
        CancelReasonType:null,
        CancelReason:'',
        CancelRemark:''
      }
      this.viodShow = true
    },
    async confirmCharge(){
      if(!this.chareMsg.MallEmployeeId){
        this.$message.error('请选择员工')
        return
      }
      try{
        let res = await crmCustomerchangeEmployeeId({
          Id:this.form.Id,
          ...this.chareMsg,
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.chargeShow = false
          this.getInfo()
        }
      }finally{
        //
      }
    },
    toChangeCharge(row){
      this.rowmsg = JSON.parse(JSON.stringify(row))
      this.chareMsg = {
        MallEmployeeId:null
      }
      this.getCharce()
      this.chargeShow = true
    },
    async getCharce(){
      try{
        let res = await employeelistbystate({
          EmployeeState:-1,
        })
        if(res.IsSuccess){
          this.chargeList = res.Result
        }
      }finally{

      }
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let res = await crmCustomerBusinessOpportunitylist({
          CRMCustomerId:this.form.Id,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize
        })
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    handleSizeChange(e){
      this.pagesize = e
      this.currentPage = 1
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    }, 
    handleClick(e){
      if(e.name=='1'){
        this.gettablelist()
      }
    },
    async handleCommand(e){
      try{
        let res = await crmCustomercooperateStateEdit({
          Id:this.form.Id,
          CooperateState:e.value,
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.getInfo()
        }
      }finally{
        //
      }
    },
    toEdit(){
      this.$router.push({
        path:'SellCustomerEdit',
        query:{
          Id:this.form.Id,
          type:1,
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
  .item-label{
    width:120px;flex-shrink:0
  }
  .width-45{
    width: 45%;
    margin-left: 10px;
  }
  .titleBox{
    background: #F8F8F9;
    border: 1px solid #EBEEF5;
    font-size: 14px;
    color: #606266;
    padding: 4px 10px;
    font-weight: 400;
  }
  .width-30{
    width: 30%;
  }
</style>