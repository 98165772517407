<template>
	<div>
		<el-dialog :title="title" :visible="dialogTag" v-loading="dialogLoading" @close="tagClosed">
			<!-- <div slot="title">
				{{title}}
			</div> -->
			<div class="FlexRow">
				<div style="fon-size:14px;line-height:37px">关键字：</div>
				<el-input placeholder="标签名称" v-model="TagNameKeyWords" style="width:250px;margin:0px 10px"></el-input>
				<el-button type="primary" @click="queryTag">查询</el-button>
			</div>
			<div v-loading="tagloading">
				<div class="scrollDiv" ref="scrollDiv" v-if="calltagList&&calltagList.length">
					<div v-for="(v, i) in calltagList" :key="v.CRMTagGroupId" class="batchTagBox">
						<div style="fon-size:14px;">{{ v.Name }}:</div>
						<div class="FlexRow" style="flex-wrap:wrap;" v-if="v.children">
							<el-checkbox-button style="margin:10px 15px 0px 0px;" v-model="m.check" @change="checkTag(m)"
								v-for="(m, n) in v.children" border :key="m.CRMTagDetailId">{{ m.Name }}
							</el-checkbox-button>
							<el-input style="width:180px;margin-top:10px" v-if="v.inputshow" :maxlength="maxlength"
								@input="limit30" v-model.trim="NewTagName" :ref="'saveTagInput' + i" size="small"
								@keyup.enter.native="handleInputConfirm(i)" @blur="handleInputConfirm(i)"
								placeholder="标签名称,输入后回车"></el-input>
							<el-button v-else-if="showAddBtn" class="pushnewbtn" size="small" @click="showInput(i)">
								+添加新标签</el-button>
						</div>
					</div>
				</div>
				<div v-else>
					<div style="font-size:14px;text-align:center;color:rgb(150,150,150);margin-top:50px">
						暂无数据
					</div>
				</div>
			</div>
			<div class="FlexRow" style="justify-content: space-between;">
				<div>
					<el-button class="pushnewbtn" size="small" @click="openaddTagListshow" v-if="showAddBtn">+添加标签组
					</el-button>
				</div>

				<div>
					<el-button @click="tagClosed">取消</el-button>
					<el-button type="primary" @click="handleConfirm">确认</el-button>
				</div>
			</div>
		</el-dialog>


		<pushtaglist :addTagListshow="addTagListshow" :addListruleForm="addListruleForm" @close="closetaglist">
		</pushtaglist>

	</div>
</template>

<script>
	import {
		crmTagGroupList,
		crmTagDailList
	} from "@/api/sv3.0.0";
	import pushtaglist from "./pushTagList"

	export default {
		components: {
			pushtaglist
		},
		props: {
			dialogTag: {
				type: Boolean,
				default: false,
			},
			selected: {
				type: Array,
				default: () => {
					return []
				},
			},
			title: {
				type: String,
				default: '设置标签'
			},
			tagkey: {
				type: String,
				default: 'Id'
			},
			showAddBtn: {
				type: Boolean,
				default: true,
			}

		},
		watch: {
			dialogTag(val) {
				if (val) {
					this.isReady = false;
					this.getgroupList();
				}
			}
		},
		data() {
			return {
				dialogLoading: false,
				tagloading: false,
				maxlength: 30,
				NewTagName: "",
				TagNameKeyWords: '',
				calltagList: [],
				addListruleForm: { //添加标签组
					Name: '',
					TagDetails: [{
						Name: ''
					}, ],
				},
				addTagListshow: false,
				chooselist:[]
			}
		},
		created() {
			// console.log(this.title,'title')
		},
		methods: {
			openaddTagListshow() {
			    this.addTagListshow = true
			},
			closetaglist() {
				this.addListruleForm =  { //添加标签组
					Name: '',
					TagDetails: [{
						Name: ''
					}, ],
				}
			  this.addTagListshow = false
				this.$forceUpdate()
			},
			tagClosed() {
				
				this.$nextTick(() => {
					this.$refs.scrollDiv.scrollTop = 0 + 'px'
				})
				this.TagNameKeyWords = ''
				this.$emit('close')

				// console.log(this.$refs.scrollDiv.scrollTop,189)
			},
			//添加新标签 显示输入框
			showInput(i) {
				// console.log(i)
				this.calltagList[i].inputshow = true
				this.$forceUpdate() //强制更新数据
				this.$nextTick(() => {
					this.$refs["saveTagInput" + i][0].$refs.input.focus();
				});
			},
			//添加新标签
			handleInputConfirm(i) {
				let tagarr = []
				if (this.NewTagName) {
					tagarr.push(this.NewTagName)
					this.totagadd(i, tagarr)
					// this.$parent.totagadd(i, tagarr)
				}
				this.NewTagName = ""
				this.calltagList[i].inputshow = false
				this.$forceUpdate()
			},
			//添加标签
			async totagadd(e, list) {
				this.tagloading = true
				let msg = await e
				let arr = await list
				try {
					let data = {
						CRMTagGroupId: this.calltagList[msg].CRMTagGroupId,
						TagDetails: arr
					}
					let result = await tagadd(data)
					if (result.IsSuccess) {
						//成功则直接添加进去  不调用接口重新获取数据
						let newtag = {
							Name: arr[0],
							check: false,
							CRMTagDetailId: result.Result.TagDetails[0].CRMTagDetailId,
							CRMTagGroupId: this.calltagList[msg].CRMTagGroupId
						}
						this.calltagList[msg].children.push(newtag)
						this.$forceUpdate()
					}
				} finally {
					this.NewTagName = "";
					this.calltagList[msg].inputshow = false
					this.tagloading = false
				}
			},
			//选择标签
			checkTag(record) {
				// console.log(record)
				if(record.check){
					this.chooselist.push(record)
				}else{
					this.chooselist = this.chooselist.filter(v=>{
						return v.CRMTagDetailId != record.CRMTagDetailId
					})
				}
			},
			//查询标签
			queryTag() {
				this.getgroupList()
			},
			limit30(e) {
				let len = 0
				let strnum = 0
				this.maxlength = 30 //每次初始化一下输入最大值  不然在输入值满了之后 删除一位就无法再输入
				for (let i = 0; i < e.length; i++) {
					var a = e.charAt(i);
					if (a.match(/[^\x00-\xff]/ig) != null) {
						//汉字占两个字符
						len += 2;
					} else {
						//其他占一个字符
						len += 1;
					}
					if (len < 30) {
						strnum += 1
					} else {
						this.maxlength = strnum
					}
				}
				// console.log(this.maxlength,len)
			},
			//获取标签组
			async getgroupList() {
				this.tagloading = true
				let data = {
					Keywords: this.TagNameKeyWords,
					OrderBy: 'CRMTagGroupId',
					IsAsc: true,
					Skip: 0,
					Take: 99999,
				}

				let result = await crmTagGroupList(data)
				if (result.IsSuccess) {
					let calltagList = result.Result.Results
					calltagList.map((v) => {
						v.inputshow = false
						v.children = []
						return v;
					})

					// console.log(this.calltagList)
					this.togettaglist(calltagList)
				}
			},
			//打标签 查询所有标签
			async togettaglist(list) {
				this.tagloading = true
				let data = {
					Keywords: this.TagNameKeyWords,
					OrderBy: 'CRMTagDetailId',
					IsAsc: true,
					Skip: 0,
					Take: 99999,
				}
				let result = await crmTagDailList(data)
				if (result.IsSuccess) {
					var all = result.Result.Results;

					// console.log(this.selected)
					//第一次打开
					if (!this.isReady) {
						this.chooselist = this.selected.map(item => {
							item.CRMTagDetailId  = item[this.tagkey]
							return item
						})
					} else {
						let arr = this.chooselist.map(v=>v.CRMTagDetailId)
						this.calltagList.map(item => {
							item.children.map(obj => {
								if (obj.check && arr.indexOf(obj.CRMTagDetailId) == -1) {
									this.chooselist.push(obj)
								}
							})
						})
					}

					var ids = this.chooselist.map(v=>v.CRMTagDetailId )
					// console.log(this.chooselist,this.selected)
					//根据子标签的父级id去分配
					var list = list.map((v) => {
						all.map((x) => {
							// console.log(ids)
							x.check = ids.indexOf(x.CRMTagDetailId) > -1;
							if (v.CRMTagGroupId == x.CRMTagGroupId) {
								v.children.push(x)
								return
							}
							return x
						})
						return v;
					})
					
					this.calltagList = list

					this.isReady = true;
				}
				this.tagloading = false;
			},
			handleConfirm() {
				// var list = [];
				// this.calltagList.map(item => {
				// 	var array = item.children.filter(obj => {
				// 		return obj.check;
				// 	})
				// 	if (array) {
				// 		list.push(...array)
				// 	}
				// })
				// console.log(this.chooselist)

				this.$emit('confirm', this.chooselist)
			}

		}
	}
</script>

<style scoped>
	.FlexRow {
		display: flex;
		flex-direction: row;
	}

	.batchTagBox {
		margin-top: 20px;
	}

	::v-deep .el-checkbox-button__inner {
		border-left: 1px solid #ddd;
		border-radius: 4px !important;
	}

	.scrollDiv {
		width: 100%;
		max-height: 300px;
		height: 300px;
		margin-top: 20px;
		overflow:hidden;
		overflow-y: auto !important;
		margin-bottom: 20px
	}

	.pushnewbtn {
		margin: 10px 15px 0px 0px;
		color: #409eff;
		border-color: #409eff;
		border-radius: 4px;
		font-size: 14px;
	}
</style>
